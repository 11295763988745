<template>
  <div class="wrap pr">
    <div id="place-chart" style="height:740px;"></div>
    <div
      v-if="nodata"
      class="pa dp-flex align-item-c justify-c"
      style="top:0;left:0;bottom:0;right:0;background-color: #00000008"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { setAxisMaxMin } from "./tool";
export default {
  props: {
    selFields: {
      type: Array,
      default: () => []
    },
    legends: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: () => ""
    },
    fields: {
      type: Object,
      defalut: () => {}
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        title: {
          text: "版位",
          left: 50,
          textStyle: {
            color: "rgba(0,0,0,.85)",
            fontWeight: 500
          }
        },
        xAxis: {},
        yAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        series: []
      },
      colors: ["#1E9493", "#CED4DE"],
      nodata: true
    };
  },
  computed: {},
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(document.getElementById("place-chart"));
      this.myChart.setOption(this.chartOption, true);
    },
    createTooltip(data, arr, legends, colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `width:400px;background-color:#fff;color:${this.themeColor};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        axisPointer: {
          type: "shadow"
        },
        formatter: params => {
          // console.log("tooltip-params", params);
          let title = params[0].axisValueLabel;
          let cur = data.find(d => d.title == title);
          let spendKey = ["spend", "industry_spend"];
          let result = "";
          result += `<p>${title}<p><p>${arr[0].label}<p>`;
          let _unit = "";
          if (arr[0].value != "inline_link_click_ctr") {
            _unit = Object.values(this.fields).find(
              f => f.showInFaceBook == arr[0].value
            ).unit;
          } else {
            _unit = "";
          }
          params.forEach((item, i) => {
            result += `<div class="dp-flex align-item-c">
              <div class="mr-10" style="width:60%;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                colors[i]
              }"></span><span style="margin-right:20px;">${
              legends[i]
            }：</span><span>${
              cur[i == 0 ? arr[0].value : "industry_" + arr[0].value]
                .value_formatted &&
              cur[i == 0 ? arr[0].value : "industry_" + arr[0].value]
                .value_formatted != "0"
                ? cur[i == 0 ? arr[0].value : "industry_" + arr[0].value]
                    .value_formatted + _unit
                : "--"
            }</span>
              </div>
              <div>${arr[1].label}：${
              cur[spendKey[i]].value_formatted &&
              cur[spendKey[i]].value_formatted != "0"
                ? cur[spendKey[i]].value_formatted + "USD"
                : "0USD"
            }</div>
            </div>`;
          });
          return result;
        }
      };
      return obj;
    },
    createxAxis(data, arr) {
      let _arr = [];
      arr.forEach((item, i) => {
        let gap = i == 0 ? 50 : 40;
        let obj = {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        };
        _arr.push(obj);
      });
      return _arr;
    },
    createSeries(data, arr, legends) {
      let _arr = [];
      legends.forEach((item, i) => {
        let key = i == 0 ? arr[0].value : "industry_" + arr[0].value;
        // console.log("data", data);
        let _data = data.map(d => {
          return d[key] && d[key].value ? d[key].value : 0;
        });
        let obj = {
          symbol: "none",
          name: `${item}${arr[0].label}数据`,
          type: "bar",
          data: _data,
          xAxisIndex: i
        };
        _arr.push(obj);
      });
      // console.log("createSeriesData", _arr);
      return _arr;
    },
    createOption(data, selArr, legends, colors) {
      this.chartOption.grid = {
        left: 50,
        bottom: 50,
        top: 50,
        right: 50,
        containLabel: true
      };
      this.chartOption.color = this.colors;
      this.chartOption.legend = {
        data: legends.map(item => `${item}${selArr[0].label}数据`),
        icon: "circle",
        bottom: 0,
        itemGap: 20,
        itemWidth: 10,
        itemHeight: 10
      };
      this.chartOption.tooltip = this.createTooltip(
        data,
        selArr,
        legends,
        colors
      );
      this.chartOption.yAxis.data = data.map(item => item.title);
      this.chartOption.yAxis.inverse = true;
      this.chartOption.yAxis.boundaryGap = true;
      this.chartOption.yAxis.alignWithLabel = true;
      this.chartOption.xAxis = this.createxAxis(data, legends);
      this.chartOption.series = this.createSeries(data, selArr, legends);
      // 设置坐标轴最大最小值和间隔
      setAxisMaxMin("xAxis", this.chartOption, {
        noDataSplitNum: 5,
        hasDataSplitNum: 5
      });
      // 无数据时文字提示
      this.nodata = this.chartOption.series.every(item =>
        item.data.every(d => d == "0" || !d)
      );
      // console.log("this.nodata", this.nodata);
      // console.log("this.placechartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    this.createOption(
      this.chartData,
      this.selFields,
      this.legends,
      this.colors
    );
  },
  watch: {
    chartData: {
      handler(val) {
        //图表初始化
        this.createOption(val, this.selFields, this.legends, this.colors);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
#place-chart {
  margin-top: 20px;
}
</style>