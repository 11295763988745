<template>
  <div class="wrap pr">
    <div id="preview-chart" style="height:310px;"></div>
    <div
      v-if="nodata"
      class="pa dp-flex align-item-c justify-c"
      style="top:0;left:0;bottom:0;right:0;background-color: #00000008"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { setAxisMaxMin, setAxisMaxMinV2 } from "./tool";
export default {
  props: {
    selArr: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: () => ""
    },
    fields: {
      type: Object,
      defalut: () => {}
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {
          type: "category",
          boundaryGap: true,
          alignWithLabel: true,
          axisLabel: {
            rotate: -60
          },
          data: []
        },
        yAxis: {},
        series: []
      },
      nodata: true
    };
  },
  computed: {},
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("preview-chart")
      );
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV2(this.myChart, this.chartOption, "yAxis", 5);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption",this.chartOption)
    },
    createTooltip(data, arr, colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${this.themeColor};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        formatter: params => {
          // console.log("tooltip-params", params);
          let title = params[0].axisValueLabel;
          let cur = data.find(d => d.date_formatted == title);
          let result = "";
          result += `<p>${title}<p>`;
          arr.forEach((item, i) => {
            let _unit = "";
            if (item.value != "inline_link_click_ctr") {
              _unit = Object.values(this.fields).find(
                f => f.showInFaceBook == item.value
              ).unit;
            } else {
              _unit = "";
            }
            result += ` <div class="dp-flex align-item-c"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              item.color
            }"></span><span style="margin-right:20px;">${
              item.label
            }：</span><span>${
              cur[item.value].value_formatted
                ? cur[item.value].value_formatted + _unit
                : "--"
            }</span>
              </div>`;
          });
          return result;
        }
      };
      return obj;
    },
    createyAxis(data, arr) {
      let _arr = [];
      arr.forEach((item, i) => {
        let gap = i == 0 ? 50 : 60;
        let obj = {
          name: item.label,
          nameLocation: "middle",
          nameGap: gap,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            formatter: function(param) {
              return param.toFixed(2);
            }
          }
        };
        _arr.push(obj);
      });
      return _arr;
    },
    createSeries(data, arr) {
      let _arr = [];
      arr.forEach((item, i) => {
        let _data = data.map(d => {
          let _item = d[item.value].value_formatted;
          let index = this.units.findIndex(u => _item.indexOf(u) != -1);
          // console.log("_item,index", _item, index);
          if (index != -1) {
            let unit = this.units[index];
            let _index = _item.indexOf(unit);
            _item = _item.slice(0, _index);
            // console.log("_item", _item);
          }
          return _item;
        });
        let obj = {
          symbol: "circle",
          symbolSize: 6,
          name: item.label,
          type: "line",
          data: _data,
          yAxisIndex: i
        };
        _arr.push(obj);
      });
      // console.log("createSeriesData", _arr);
      return _arr;
    },
    createOption(data, selArr) {
      if (selArr.length) {
        this.chartOption.grid = {
          left: 50,
          bottom: 50,
          top: 20,
          right: 50,
          containLabel: true
        };
        this.chartOption.color = selArr.map(item => item.color);
        this.chartOption.legend = {
          data: selArr.map(item => item.label),
          icon: "circle",
          bottom: 0,
          itemGap: 20,
          itemWidth: 10,
          itemHeight: 10
        };
        // this.chartOption.tooltip = { trigger: "axis" };
        this.chartOption.tooltip = this.createTooltip(
          data,
          selArr,
          this.colors
        );
        this.chartOption.xAxis.data = data.map(item => item.date_formatted);
        this.chartOption.yAxis = this.createyAxis(data, selArr);
        this.chartOption.series = this.createSeries(data, selArr);
        // 三个字段时不显示y轴同时改变grid
        if (this.selArr.length == 3) {
          this.chartOption.yAxis.forEach(item => {
            item.axisLabel = {
              show: false
            };
            item.name = "";
            item.nameGap = 0;
          });
          this.chartOption.grid.left = 20;
          this.chartOption.grid.right = 40;
        }
        // 设置坐标轴最大最小值和间隔
        // setAxisMaxMin("yAxis", this.chartOption, {
        //   noDataSplitNum: 5,
        //   hasDataSplitNum: 5
        // });
        // 无数据时文字提示
        this.nodata = this.chartOption.series.every(item =>
          item.data.every(d => d == "0" || !d)
        );
        // console.log("this.overviewchartOption", this.chartOption);
        this.updateChart();
      }
    }
  },
  mounted() {
    //图表初始化
    this.createOption(this.chartData, this.selArr);
  },
  watch: {
    chartData: {
      handler(val) {
        //图表初始化
        this.createOption(val, this.selArr);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
#preview-chart {
  margin-top: 20px;
  // background-color: #00000008;
}
</style>