<template>
  <div class="overview-wrap" :style="`height:${tableHeight}px;overflow-y:auto;overflow-x:hidden;`">
    <!-- 广告数据概览 -->
    <div class="ad-wrap mb-16 bg-f">
      <div class="header dp-flex justify-btw">
        <div>
          <span>广告数据概览</span>
          <!-- <span class="update-time">数据更新至2020-10-19 05:25:09</span> -->
        </div>
        <!-- date-picker -->
        <date-picker
          :initDateRange="defDateRange"
          @change-date="handleChangeDate"
        ></date-picker>
      </div>
      <div class="content">
        <!-- 字段卡片 -->
        <field-cards
          v-if="hasFields"
          :fields="fields"
          :start_date="start_date"
          :end_date="end_date"
          :colors="colors"
          @post-selArr="getSelArr"
        ></field-cards>
        <!-- 概览chart -->
        <overview-chart
          v-if="previewChartData.length"
          :selArr="selArr"
          :chartData="previewChartData"
          :units="units"
          :themeColor="themeColor"
          :fields="fields"
        ></overview-chart>
      </div>
    </div>
    <!-- 行业和细分数据 -->
    <div class="detail-wrap bg-f">
      <div class="header border-btm">
        行业和细分数据
        <el-dropdown
          class="ml-10"
          size="small"
          placement="bottom-start"
          @command="
            c => {
              selField = c;
            }
          "
        >
          <span
            >{{ selField }}<i class="el-icon-arrow-down el-icon--right"></i
          ></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, name) in fields"
              :key="value.showInFaceBook"
              :command="name"
              >{{ name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="content">
        <el-row :gutter="50">
          <!-- 版位 -->
          <el-col :span="13">
            <place-chart
              v-if="placeChartData.length"
              :selFields="detailSelFields"
              :legends="legends"
              :chartData="placeChartData"
              :units="units"
              :themeColor="themeColor"
              :fields="fields"
            ></place-chart>
          </el-col>
          <el-col :span="11">
            <!-- 创意 (无接口暂时隐藏)-->
            <creative-chart
              class="dp-none"
              v-if="placeChartData.length"
              :selFields="detailSelFields"
              :legends="legends"
              :chartData="placeChartData"
              :units="units"
              :themeColor="themeColor"
            ></creative-chart>
            <!-- 性别 -->
            <gender-chart
              v-if="genderChartData.length"
              :selFields="detailSelFields"
              :legends="legends"
              :chartData="genderChartData"
              :sum="genderSum"
              :units="units"
              :themeColor="themeColor"
              :fields="fields"
            ></gender-chart>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// 组件
import datePicker from "@/views/components/datePicker";
import fieldCards from "./fieldCards";
import overviewChart from "./overviewChart";
import placeChart from "./placeChart";
import creativeChart from "./creativeChart";
import genderChart from "./genderChart";
// 接口
import {
  getFields,
  getOverviewDetails,
  breakdown
} from "@/api/accountOverview";
// tool.js
import { arrSortFn } from "./tool";

import { tableHeight } from "@/utils/utils.js";

export default {
  components: {
    datePicker,
    fieldCards,
    overviewChart,
    placeChart,
    creativeChart,
    genderChart
  },
  data() {
    return {
      tableHeight: 500,
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" }
      ],
      // 日期
      defDateRange: [],
      start_date: "",
      end_date: "",
      colors: [
        "rgb(91, 143, 249)",
        "rgb(90, 216, 166)",
        "rgb(232, 100, 82)",
        "rgb(30, 148, 147)",
        "rgb(255, 152, 69)"
      ],
      // 字段下拉数据
      hasFields: false,
      fields: {},
      // 选中的卡片数据
      selArr: [],
      // 中间拆线chart数据
      previewChartData: [],
      // 行业细分选定的字段
      selField: "",
      // legends
      legends: ["广告帐户", "电商行业"],
      // 版位chart数据
      placeChartData: [],
      // 性别chart数据
      genderChartData: [],
      genderSum: {},
      // 字段单位
      units: ["USD", "%", "次"],
      themeColor: "rgba(0,0,0,.85)"
    };
  },
  computed: {
    ...mapState("num", ["activeAdAccount"]),
    // 以下参数改变重新调用中间chart接口
    dataChangeParams() {
      return {
        end_date: this.end_date,
        fields: this.selArr.map(item => item.value),
        start_date: this.start_date
      };
    },
    commandField() {
      return function(obj) {
        // console.log("commandField", obj);
        return obj.name;
      };
    },
    // 细分要展示的字段
    detailSelFields() {
      return [
        {
          label: this.selField,
          value: this.fields[this.selField].showInFaceBook
        },
        { label: "Spend", value: "spend" }
      ];
    },
    // 以下参数改变重新调用breakdown接口
    detailsChangeParams() {
      return {
        end_date: this.end_date,
        selField: this.selField,
        start_date: this.start_date
      };
    }
  },
  methods: {
    sortResData(data, selFields) {
      let allNoVal = data.every(
        item =>
          !(item[selFields[0].value] && item[selFields[0].value].value) ||
          (item[selFields[0].value] && item[selFields[0].value].value == "0")
      );
      if (!allNoVal) {
        // 按照第一个展示字段降序排列返回数据
        data = arrSortFn(data, selFields[0].value);
      } else {
        // 如果返回数据第一个字段值全为'0'或不存在则按第二个字段降序排列
        data = arrSortFn(data, "industry_" + selFields[0].value);
      }
      return data;
    },
    // 根据fields构造字段单位
    createUnits(data) {
      let arr = [];
      let values = Object.values(data);
      let units = values.map(item => item.unit);
      arr = Array.from(new Set(units));
      return arr.filter(item => item);
    },
    // 字段下拉数据-api
    getFields() {
      this.$showLoading();
      getFields(this.$store.state.num.activeAdAccount.id).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          if (res.data != {}) {
            this.fields = res.data;
            this.units = this.createUnits(res.data);
            this.selField = "CTR";
            this.hasFields = true;
          }
        }
      });
    },
    // 日期改变
    handleChangeDate(val) {
      if (val.length) {
        this.start_date = val[0];
        this.end_date = val[1];
      }
    },
    getSelArr(val) {
      this.selArr = val;
    },
    // 中间拆线chart
    getOverviewDetails() {
      this.$showLoading();
      let params = {
        ad_account_ids: [this.$store.state.num.activeAdAccount.id],
        end_date: this.end_date,
        fields: this.selArr.map(item => item.value),
        start_date: this.start_date
      };
      getOverviewDetails(params).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.previewChartData = res.data;
        }
      });
    },
    // 获取细分数据
    breakdown(type) {
      this.$showLoading();
      let params = {
        ad_account_ids: [this.$store.state.num.activeAdAccount.id],
        end_date: this.end_date,
        field: this.fields[this.selField].showInFaceBook,
        limit_type: [type],
        start_date: this.start_date
      };
      return breakdown(params).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          return res;
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      // console.log("nexttick");
      if (this.tableHeightData.length) {
        this.tableHeight = tableHeight(this.tableHeightData);
        // console.log("this.tableHeight", this.tableHeight);
      }
    });
    // 字段下拉数据-api
    this.getFields();
  },
  watch: {
    dataChangeParams: {
      handler(val) {
        // 中间拆线chart
        if (val.fields.length) {
          this.getOverviewDetails();
        }
      },
      deep: true
    },
    detailsChangeParams: {
      handler(val) {
        // console.log("detailsChangeParams-val", val);
        if (val.selField) {
          // 版位
          this.breakdown("placement").then(res => {
            // 根据字段排序
            let _data = this.sortResData(
              res.data.placement,
              this.detailSelFields
            );
            // console.log("_data", _data);
            this.placeChartData = _data;
            // console.log("this.placeChartData", this.placeChartData);
          });
          // 创意
          // this.breakdown("creative");
          // 性别
          this.breakdown("age").then(res => {
            this.genderChartData = res.data.age.list;
            this.genderSum = res.data.age.summary;
            // console.log(" this.genderChartData", this.genderChartData);
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
.overview-wrap {
  .header {
    padding: 16px 24px 8px 32px;
    .update-time {
      color: rgb(185, 187, 190);
      margin-left: 16px;
    }
  }
  .content {
    padding: 16px 16px 40px;
    &:last-child {
      padding-top: 40px;
    }
  }
  .border-btm {
    border-bottom: 1px solid rgb(229, 230, 233);
  }
}
</style>